import { SlideNumber, initializeSliderOnView } from './slide-func.js';

document.addEventListener("DOMContentLoaded", function () {
  $(function () {
    const promoSliderConfig = {
      perPage: 6,
      perMove: 6,
      pagination: true,
      gap: "20px",
      interval: 3000,
      breakpoints: {
        1979: {
          perPage: 5,
          perMove: 5,
        },
        1279: {
          perPage: 4,
          perMove: 4,
        },
        991: {
          perPage: 3,
          perMove: 3,
        },
        767: {
          pagination: false,
          perPage: 2,
          perMove: 2,
        },
        575: {
          pagination: false,
          perPage: 1,
          perMove: 1,
          padding: {
            right: 80,
          },
        },
      },
    };

    // Inicjalizacja slidera dla komponentu 
    if ($("[data-promo-box-slider]").length > 0) {
      $("[data-promo-box-slider] .promoSlider").each(function () {
        var sliderId = $(this).attr("id");
        initializeSliderOnView('.promoBoxSliderSection', `#${sliderId}`, promoSliderConfig, SlideNumber);
      });
    }
  });
});
